@use '~theme/typography'as *;

.expandable-component {
	.header {
		@include font-regular-text-md;
		min-height: 30px;
		display: flex;
		cursor: pointer;
		align-items: center;
        line-height: 16px;
		border: none;
		width: 100%;

        &.right-arrow {
            display: flex;
            flex-direction: row-reverse;
        }

		.title-content {
			line-height: 30px;
		}

		.open-close-container {
			margin-left: 10px;
			line-height: 30px;
			display: flex;
			align-items: center;

			&.open-icon {
				animation: openAnimation 0.1s ease-in-out;
			}

			&.close-icon {
				animation: closeAnimation 0.1s ease-in-out;
			}
		}
	}


	.expandable-content {
		display: block;
		overflow: hidden;
	}
}

@keyframes openAnimation {
	0% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes closeAnimation {
	0% {
		transform: rotate(-180deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
