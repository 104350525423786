@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.toggle-button-container {

	height: 32px;
	display: flex;
	width: fit-content;
	overflow: hidden;

	.toggle-button {
		@include unselectable;
		@include font-regular-text-md;
		@include create-border($silver, 0);
		display: flex;
		height: 100%;
		background: none;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: black;

		& + .toggle-button {
			border-left: 1px;
		}

		&.active {
			background: $dodger-blue;
			color: $white;
			pointer-events: none;

			&:hover {
				background-color: $dodger-blue;
			}
		}

		.toggle-text {
			@include font-regular-text-md;
			line-height: 16px;
		}

		svg {
			margin-right: 7px;
		}

		&:focus {
			outline: none;
		}

		&:hover {
			background-color: $wild-sand;
		}

		// important to prevent ios override
		padding: 1px 6px !important;
	}

	.toggle-button:last-of-type {
		border-radius: 0px 4px 4px 0px;
	}

	.toggle-button:first-of-type {
		border-radius: 4px 0px 0px 4px;
	}
}
